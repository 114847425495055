import * as types from './types'
import { createAction } from 'Utils/redux'

export const addItem = createAction(
  types.LOOKUP_ADD_ITEM,
  'list',
  'name',
  'language'
)
export const addItemSucceed = createAction(
  types.LOOKUP_ADD_ITEM_SUCCEED,
  'list',
  'value',
  'name'
)
export const clearLookup = createAction(types.LOOKUP_CLEAR_LOOKUP)
export const deleteItem = createAction(
  types.LOOKUP_DELETE_ITEM,
  'list',
  'value',
  'language'
)
export const deleteItemSucceed = createAction(
  types.LOOKUP_DELETE_ITEM_SUCCEED,
  'list',
  'value'
)
export const getLookup = createAction(
  types.LOOKUP_GET_LOOKUP,
  'lookup',
  'language'
)
export const importExcel = createAction(types.LOOKUP_IMPORT_EXCEL, 'payload')
export const importExcelUploading = createAction(
  types.LOOKUP_IMPORT_EXCEL_UPLOADING,
  'value'
)
export const loadCountries = createAction(types.LOOKUP_LOAD_COUNTRIES)
export const loadLanguages = createAction(types.LOOKUP_LOAD_LANGUAGES)
export const moveItem = createAction(
  types.LOOKUP_MOVE_ITEM,
  'list',
  'value',
  'oldPosition',
  'newPosition',
  'language'
)
export const moveItemIntern = createAction(
  types.LOOKUP_MOVE_ITEM_INTERN,
  'list',
  'value',
  'oldPosition',
  'newPosition',
  'language'
)

export const restoreAll = createAction(
  types.LOOKUP_RESTORE_ALL,
  'list',
  'language'
)
export const restoreItem = createAction(
  types.LOOKUP_RESTORE_ITEM,
  'list',
  'value',
  'language'
)
export const restoreItemSucceed = createAction(
  types.LOOKUP_RESTORE_ITEM_SUCCEED,
  'list',
  'value'
)
export const setCountries = createAction(types.LOOKUP_SET_COUNTRIES, 'data')
export const setLanguage = createAction(types.LOOKUP_SET_LANGUAGE, 'language')
export const setLanguages = createAction(types.LOOKUP_SET_LANGUAGES, 'data')
export const setLookup = createAction(
  types.LOOKUP_SET_LOOKUP,
  'list',
  'child',
  'childArray'
)
export const sortList = createAction(types.LOOKUP_SORT_LIST, 'list', 'language')
export const updateItem = createAction(
  types.LOOKUP_UPDATE_ITEM,
  'list',
  'value',
  'name',
  'info',
  'child',
  'language',
  'color',
  'code',
  'required',
  'childArray'
)
export const updateItemSucceed = createAction(
  types.LOOKUP_UPDATE_ITEM_SUCCEED,
  'list',
  'value',
  'name',
  'info',
  'child',
  'color',
  'code',
  'required',
  'childArray'
)
