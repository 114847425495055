import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import {
  addCompanies,
  addFilters,
  addLookups,
  addTags,
  addTemplates,
  addUsers,
  addViewers,
  filtersLoaded,
  loadCompaniesFinished,
  loadingTemplates,
  loadingUsers,
  loadUsersFinished,
} from 'Actions/cache'
import { getEntity } from 'Root/app/IntlProvider'

export function* loadCompaniesSaga() {
  const { ok, data, unauthorized } = yield http.get('/companies')
  if (ok) {
    yield put(addCompanies(data))
  } else if (unauthorized) {
    // Do nothing
  } else {
    yield call(globalError, data)
  }
  yield put(loadCompaniesFinished())
}

export function* loadFiltersSaga(action) {
  const { force } = action
  if (!force) {
    const state = yield select()
    if (state.cache.filters.loaded) {
      yield put(filtersLoaded())
      return
    }
  }

  const { ok, data } = yield http.get('/filters')
  if (ok) {
    yield put(addFilters(data))
    yield put(filtersLoaded())
  } else {
    yield call(globalError, data)
  }
}

export function* loadTagsSaga(action) {
  const { fromEntity } = action
  const state = yield select()
  const tags = state.cache.tags
  const entity = yield call(getEntity, fromEntity, true)
  if (tags[fromEntity].length === 0) {
    const { ok, data } = yield http.get(`/${entity}/tags`)
    if (ok) {
      yield put(addTags(fromEntity, data))
    } else {
      yield call(globalError, data)
    }
  }
}

export function* loadUsersSaga() {
  try {
    yield put(loadingUsers(true))
    const { ok, data, unauthorized } = yield http.get('/users')
    if (ok) {
      yield put(addUsers(data))
    } else if (unauthorized) {
      // Do nothing
    } else {
      yield call(globalError, data)
    }
    yield put(loadUsersFinished())
  } finally {
    yield put(loadingUsers(false))
  }
}

export function* loadLookupsSaga() {
  const { ok, unauthorized, data } = yield http.get(`/lookups`)
  if (ok) {
    yield put(addLookups(data))
  } else if (unauthorized) {
    // Do nothing
  } else {
    yield call(globalError, data)
  }
}

export function* loadTemplatesSaga(action) {
  const { user = false } = action
  try {
    yield put(loadingTemplates(true))
    const { ok, data } = yield http.get(`/templates${user ? '/user' : ''}`)
    if (ok) {
      yield put(addTemplates(data))
    } else {
      yield call(globalError, data)
    }
  } finally {
    yield put(loadingTemplates(false))
  }
}

export function* loadViewersSaga() {
  try {
    yield put(loadingUsers(true))
    const { ok, data } = yield http.get('/users/viewers')
    if (ok) {
      yield put(addViewers(data))
    } else {
      yield call(globalError, data)
    }
  } finally {
    yield put(loadingUsers(false))
  }
}
