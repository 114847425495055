import { call, delay, put, select, take } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  getDefinition,
  gotoTab,
  setDefinition,
  setDefinitionLoaded,
  setLoading,
  widgetGet,
  widgetSet,
} from 'Actions/dashboard'
import { globalError } from './shell'
import { gotoUrl } from 'Actions/app'
import * as types from 'Actions/types'

export function* getDefinitionSaga() {
  const { ok, error, data } = yield http.get('/dashboard/definition')
  if (ok) {
    yield put(setDefinition(data))
    yield put(setDefinitionLoaded())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* gotoTabSaga({ tab }) {
  yield put(gotoUrl({ url: `/dashboard/${tab}` }))
}

export function* setTabNameSaga({ payload }) {
  const { tab, name } = payload
  const { ok, error, data } = yield http.post(`/dashboard/tabs`, {
    tab,
    name,
  })
  if (ok) {
    yield put(getDefinition())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* widgetAddSaga({ payload }) {
  const { currentTab, tab, previousWidget, widget } = payload
  yield put(setLoading(true))
  const { ok, error, data } = yield http.post(`/dashboard/widgets`, {
    previousWidget,
    tab,
    widget,
  })
  if (ok) {
    yield put(getDefinition())
    yield put(widgetGet({ widgets: [widget] }))
    if (currentTab !== tab) {
      yield take(types.DASHBOARD_SET_DEFINITION_LOADED)
      yield put(gotoTab(tab))
    }
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* widgetGetSaga({ payload }) {
  const { widgets = [], tab, force = false } = payload
  yield put(setLoading(true))
  const { ok, error, data } = yield http.post('/dashboard', {
    tab,
    widgets,
    force,
  })
  if (ok) {
    yield put(widgetSet(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* widgetMoveSaga({ payload }) {
  const { left, right, tab, widget } = payload
  const { ok, error, data } = yield http.post(
    `/dashboard/widgets/${widget}/move`,
    {
      left,
      right,
      tab,
    }
  )
  if (ok) {
    yield put(getDefinition())
    if (tab) {
      yield take(types.DASHBOARD_SET_DEFINITION_LOADED)
      yield put(gotoTab(tab))
    }
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* widgetRemoveSaga({ payload }) {
  const { widget } = payload
  const { noContent, error, data } = yield http.delete(
    `/dashboard/widgets/${widget}`
  )
  if (noContent) {
    yield put(getDefinition())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* widgetSetInvibleSaga(action) {
  const { widget } = action
  yield delay(200)
  const state = yield select()
  const invisible = state.dashboard.widget[widget].invisible
  yield http.post(`/dashboard/widgets/${widget}/invisible`, {
    invisible,
  })
}

export function* widgetSetSettingSaga({ payload }) {
  const { setting, value, widget } = payload
  const { ok, error, data } = yield http.post(
    `/dashboard/widgets/${widget}/settings`,
    {
      setting,
      value,
    }
  )
  if (ok) {
    // refresh widget data
    yield put(widgetGet({ widgets: [widget] }))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* widgetSizeSaga({ payload }) {
  const { full, widget } = payload
  const { ok, error, data } = yield http.post(
    `/dashboard/widgets/${widget}/size`,
    {
      full,
    }
  )
  if (ok) {
    yield put(getDefinition())
  } else if (error) {
    yield call(globalError, data)
  }
}
