import * as types from './types'
import { createAction } from 'Utils/redux'

export const addDuplicates = createAction(
  types.CANDIDATE_ADD_DUPLICATES,
  'data'
)
export const addEducation = createAction(
  types.CANDIDATE_ADD_EDUCATION,
  'payload'
)
export const addEducationSucceed = createAction(
  types.CANDIDATE_ADD_EDUCATION_SUCCEED,
  'data'
)
export const addEmployment = createAction(
  types.CANDIDATE_ADD_EMPLOYMENT,
  'payload'
)
export const addEmploymentSucceed = createAction(
  types.CANDIDATE_ADD_EMPLOYMENT_SUCCEED,
  'data'
)
export const addShortlist = createAction(
  types.CANDIDATE_ADD_SHORTLIST,
  'id',
  'relation'
)
export const addSkill = createAction(types.CANDIDATE_ADD_SKILL, 'payload')
export const addSkillSucceed = createAction(
  types.CANDIDATE_ADD_SKILL_SUCCEED,
  'data'
)
export const addSources = createAction(types.CANDIDATE_ADD_SOURCES, 'data')
export const addTalentpool = createAction(
  types.CANDIDATE_ADD_TALENTPOOL,
  'id',
  'talentpool'
)
export const addToTalentpool = createAction(
  types.CANDIDATE_TALENTPOOL_ADD,
  'id'
)
export const checkDuplicate = createAction(
  types.CANDIDATE_CHECK_DUPLICATE,
  'payload'
)
export const createShare = createAction(types.CANDIDATE_CREATE_SHARE, 'payload')
export const deleteAlternative = createAction(
  types.CANDIDATE_DELETE_ALTERNATIVE,
  'id'
)
export const deleteAlternativeSucceed = createAction(
  types.CANDIDATE_DELETE_ALTERNATIVE_SUCCEED
)
export const deleteCover = createAction(types.CANDIDATE_DELETE_COVER, 'id')
export const deleteCoverSucceed = createAction(
  types.CANDIDATE_DELETE_COVER_SUCCEED
)
export const deleteEducation = createAction(
  types.CANDIDATE_DELETE_EDUCATION,
  'id',
  'name'
)
export const deleteEducationSucceed = createAction(
  types.CANDIDATE_DELETE_EDUCATION_SUCCEED,
  'id'
)
export const deleteEmployment = createAction(
  types.CANDIDATE_DELETE_EMPLOYMENT,
  'id',
  'name'
)
export const deleteEmploymentSucceed = createAction(
  types.CANDIDATE_DELETE_EMPLOYMENT_SUCCEED,
  'id'
)
export const deletePhoto = createAction(types.CANDIDATE_DELETE_PHOTO, 'id')
export const deletePhotoSucceed = createAction(
  types.CANDIDATE_DELETE_PHOTO_SUCCEED
)
export const deleteResume = createAction(types.CANDIDATE_DELETE_RESUME, 'id')
export const deleteResumeSucceed = createAction(
  types.CANDIDATE_DELETE_RESUME_SUCCEED
)
export const deleteSkill = createAction(
  types.CANDIDATE_DELETE_SKILL,
  'id',
  'name'
)
export const deleteSkillSucceed = createAction(
  types.CANDIDATE_DELETE_SKILL_SUCCEED,
  'id'
)
export const extendGdpr = createAction(types.CANDIDATE_EXTEND_GDPR, 'payload')
export const getCompanyShares = createAction(types.CANDIDATE_GET_COMPANY_SHARES)
export const getCompanySharesSucceed = createAction(
  types.CANDIDATE_GET_COMPANY_SHARES_SUCCEED,
  'data'
)
export const getCredits = createAction(types.CANDIDATE_GET_CREDITS)
export const getCreditsSucceed = createAction(
  types.CANDIDATE_GET_CREDITS_SUCCEED,
  'data'
)
export const getGdprInfo = createAction(
  types.CANDIDATE_GET_GDPR_INFO,
  'token',
  'gdprType'
)
export const getGdprInfoSucceed = createAction(
  types.CANDIDATE_GET_GDPR_INFO_SUCCEED,
  'values'
)

export const getJobs = createAction(types.CANDIDATE_GET_JOBS_INIT, 'id')
export const getJobsSucceed = createAction(
  types.CANDIDATE_GET_JOBS,
  'id',
  'data'
)
export const getJson = createAction(types.CANDIDATE_GET_JSON, 'id')
export const getJsonSucceed = createAction(
  types.CANDIDATE_GET_JSON_SUCCEED,
  'data'
)
export const getProfileShares = createAction(types.CANDIDATE_GET_PROFILE_SHARES)
export const getProfileSharesSucceed = createAction(
  types.CANDIDATE_GET_PROFILE_SHARES_SUCCEED,
  'data'
)
export const getProjects = createAction(types.CANDIDATE_GET_PROJECTS, 'id')
export const getProjectsSucceed = createAction(
  types.CANDIDATE_GET_PROJECTS_SUCCEED,
  'id',
  'data'
)
export const getShareDefaults = createAction(types.CANDIDATE_GET_SHARE_DEFAULTS)
export const getShares = createAction(types.CANDIDATE_GET_SHARES, 'payload')
export const getSharesSucceed = createAction(
  types.CANDIDATE_GET_SHARES_SUCCEED,
  'data'
)
export const getShareViews = createAction(types.CANDIDATE_GET_SHARE_VIEWS, 'id')
export const getShareViewsSucceed = createAction(
  types.CANDIDATE_GET_SHARE_VIEWS_SUCCEED,
  'data'
)

export const getShortlist = createAction(
  types.CANDIDATE_GET_SHORTLIST_INIT,
  'id'
)
export const getShortlistSucceed = createAction(
  types.CANDIDATE_GET_SHORTLIST,
  'id',
  'data'
)
export const getViewers = createAction(types.CANDIDATE_GET_VIEWERS, 'id')
export const loadDuplicates = createAction(types.CANDIDATE_LOAD_DUPLICATES)
export const loadSources = createAction(types.CANDIDATE_LOAD_SOURCES)
export const merge = createAction(types.CANDIDATE_MERGE, 'payload')
export const mergeAddInfo = createAction(types.CANDIDATE_MERGE_ADD_INFO, 'data')
export const mergeGetInfo = createAction(types.CANDIDATE_MERGE_GET_INFO)
export const mergeReset = createAction(types.CANDIDATE_MERGE_RESET)
export const mergeSelect = createAction(types.CANDIDATE_MERGE_SELECT, 'value')
export const parseExistingResume = createAction(
  types.CANDIDATE_PARSE_EXISTING_RESUME,
  'id'
)
export const parseResume = createAction(types.CANDIDATE_PARSE_RESUME, 'values')
export const processRequestGdpr = createAction(
  types.CANDIDATE_PROCESS_REQUEST_GDPR,
  'payload'
)
export const removeFromTalentpool = createAction(
  types.CANDIDATE_TALENTPOOL_REMOVE,
  'id'
)
export const removeShare = createAction(types.CANDIDATE_REMOVE_SHARE, 'id')
export const removeShortlist = createAction(
  types.CANDIDATE_REMOVE_SHORTLIST,
  'id',
  'relationIds'
)
export const removeTalentpool = createAction(
  types.CANDIDATE_REMOVE_TALENTPOOL,
  'id',
  'talentpool'
)
export const selectJobs = createAction(
  types.CANDIDATE_SELECT_JOBS,
  'id',
  'jobs'
)
export const sendGdprConsent = createAction(
  types.CANDIDATE_SEND_GDPR_CONSENT,
  'payload'
)
export const sendGdprRequest = createAction(
  types.CANDIDATE_SEND_GDPR_REQUEST,
  'payload'
)
export const sendShare = createAction(types.CANDIDATE_SEND_SHARE, 'payload')
export const setBarButton = createAction(
  types.CANDIDATE_SET_BARBUTTON,
  'button',
  'visible',
  'disabled'
)
export const setData = createAction(types.CANDIDATE_SET_DATA, 'data')
export const setJobsFilter = createAction(
  types.CANDIDATE_SET_JOBS_FILTER,
  'filter'
)
export const setJobsLoaded = createAction(
  types.CANDIDATE_SET_JOBS_LOADED,
  'value'
)
export const setParsingBusy = createAction(
  types.CANDIDATE_SET_PARSING_BUSY,
  'busy',
  'resumeId'
)
export const setProjectsLoaded = createAction(
  types.CANDIDATE_SET_PROJECTS_LOADED,
  'value'
)
export const setSourcesDirty = createAction(
  types.CANDIDATE_SET_SOURCES_DIRTY,
  'value'
)
export const setUploading = createAction(
  types.CANDIDATE_SET_UPLOADING,
  'doc',
  'value'
)
export const setSelectBarJobId = createAction(
  types.CANDIDATE_SELECTBAR_SET_JOBID,
  'value'
)
export const setSelectBarMatching = createAction(
  types.CANDIDATE_SELECTBAR_SET_MATCHING,
  'value'
)
export const setShareDefaults = createAction(
  types.CANDIDATE_SET_SHARE_DEFAULTS,
  'data'
)

export const shortlistClearSelect = createAction(
  types.CANDIDATE_SHORTLIST_CLEAR_SELECT
)
export const shortlistDeselect = createAction(
  types.CANDIDATE_SHORTLIST_DESELECT,
  'id'
)
export const shortlistInverseSelection = createAction(
  types.CANDIDATE_SHORTLIST_INVERSE_SELECTION,
  'ids'
)
export const shortlistSelect = createAction(
  types.CANDIDATE_SHORTLIST_SELECT,
  'id'
)
export const showShares = createAction(types.CANDIDATE_SHOW_SHARES, 'payload')
export const updateRating = createAction(
  types.CANDIDATE_UPDATE_RATING,
  'id',
  'value'
)
export const updateEducation = createAction(
  types.CANDIDATE_UPDATE_EDUCATION,
  'payload'
)
export const updateEducationSucceed = createAction(
  types.CANDIDATE_UPDATE_EDUCATION_SUCCEED,
  'data'
)
export const updateEmployment = createAction(
  types.CANDIDATE_UPDATE_EMPLOYMENT,
  'payload'
)
export const updateEmploymentSucceed = createAction(
  types.CANDIDATE_UPDATE_EMPLOYMENT_SUCCEED,
  'data'
)
export const updateShare = createAction(types.CANDIDATE_UPDATE_SHARE, 'payload')
export const updateSkill = createAction(types.CANDIDATE_UPDATE_SKILL, 'payload')
export const updateSkillSucceed = createAction(
  types.CANDIDATE_UPDATE_SKILL_SUCCEED,
  'data'
)
export const upgradeResume = createAction(types.CANDIDATE_UPGRADE_RESUME, 'id')
export const uploadAlternative = createAction(
  types.CANDIDATE_UPLOAD_ALTERNATIVE,
  'id',
  'file'
)
export const uploadCover = createAction(
  types.CANDIDATE_UPLOAD_COVER,
  'id',
  'file'
)
export const uploadPhoto = createAction(
  types.CANDIDATE_UPLOAD_PHOTO,
  'id',
  'file'
)
export const uploadPhotoSucceed = createAction(
  types.CANDIDATE_UPLOAD_PHOTO_SUCCEED,
  'id',
  'url'
)
export const uploadResume = createAction(
  types.CANDIDATE_UPLOAD_RESUME,
  'id',
  'file',
  'reload'
)
export const zoomInPdf = createAction(types.CANDIDATE_PDF_ZOOMIN)
export const zoomOutPdf = createAction(types.CANDIDATE_PDF_ZOOMOUT)
