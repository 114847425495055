import deepmerge from 'deepmerge'
import { createSelector } from 'reselect'
import { toArray } from 'Utils/lookup'
import { role } from 'Utils/constants'
import { plugins, isPluginAvailable } from 'Selectors/auth'

const getRole = (state) => state.auth.user.role
const getAvailablePipelines = (state) => state.auth.company.settings.pipelines
const getPipelinesLookup = (state) => state.cache.lookups.pipeline
const getPhasesLookup = (state) => state.cache.lookups.phase
const getUserPhases = (state) => state.auth.user.phases
const getLookup = (state) => state.lookup.list
const getChild = (state) => state.lookup.child
const getLookupDef = (state, props) =>
  state.cache.lookups['lookupList']
    ? state.cache.lookups['lookupList'][props.list]
    : null
const getLookupList = (state, list) => state.cache.lookups[list]
const hasPipelines = (state) => isPluginAvailable(state, plugins.pipelines)

export const getListItems = createSelector([getLookup], (items) => {
  return items
})

export const countInactiveItems = createSelector(
  [getLookup],
  (items) => items.filter((x) => x.mutation.deleted).length
)

export const getChildListItems = createSelector([getChild], (items) =>
  items.filter((x) => !x.mutation.deleted)
)

export const hasDeletedItems = createSelector([getLookup], (items) =>
  items.some((x) => x.mutation.deleted)
)

export const hasElements = createSelector([getLookupList], (lookup) => {
  if (!lookup) {
    return false
  }
  if (Object.entries(lookup).length === 0 && lookup.constructor === Object) {
    return false
  }
  const elements = toArray(lookup).filter((x) => !x.mutation.deleted)
  return elements.length
})

export const getPipelines = createSelector(
  [getPipelinesLookup, getAvailablePipelines],
  (pipelines, availabe) => {
    if ((availabe || []).length === 0) {
      return pipelines
    }
    const result = {}
    let order = 1
    if (pipelines) {
      for (const item of availabe) {
        result[item] = deepmerge({}, pipelines[item])
        result[item].order = order
        order++
      }
    }
    return result
  }
)

export const showPipelineField = createSelector(
  [hasPipelines, getPipelinesLookup],
  (plugin, pipelines) => {
    if (!plugin) {
      return false
    }
    const list = Object.values(pipelines || {}).filter(
      (x) => !x.mutation.deleted
    )
    if (list.length > 1) {
      return true
    }
    return false
  }
)

export const getTitle = createSelector([getLookupDef], (lookup) => {
  if (lookup) {
    return lookup.name
  }
  return 'Unknown'
})

export const canManage = createSelector(
  [getLookupDef, getRole],
  (lookup, userRole) => {
    if (userRole !== role.admin) {
      return false
    }
    if (lookup) {
      return !lookup.system
    }
    return false
  }
)

export const getDefaultIcon = createSelector([getLookupDef], (lookup) =>
  lookup ? lookup.icon : ''
)

export const getPipelinePhases = createSelector(
  [getPipelinesLookup, getPhasesLookup, getUserPhases],
  (pipelines, lookup, user) => {
    const result = {}
    const workflows = Object.keys(pipelines || {})
    if (workflows.length === 0) {
      return result
    }
    for (const workflow of workflows) {
      result[workflow] = {}
      const phases = user
        .filter((x) => x.visible && x.pipeline === workflow)
        .map((x) => x.id)
      for (const key in lookup) {
        if (phases.includes(key)) {
          result[workflow][key] = lookup[key]
        }
      }
    }
    return result
  }
)
