import { combineReducers } from 'redux'
import deepmerge from 'deepmerge'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const infoReducer = createReducer(initialState.dashboard.info, {
  [types.DASHBOARD_SET_DEFINITION_LOADED]: (state) => ({
    ...state,
    definitionLoaded: true,
  }),
  [types.DASHBOARD_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.DASHBOARD_SET_SELECTION]: (state, { selection }) => ({
    ...state,
    selection,
  }),
  [types.DASHBOARD_WIDGET_GET]: (state, { payload }) => ({
    ...state,
    currentTab: payload.tab || state.currentTab,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.dashboard.info,
  }),
})

const tabReducer = createReducer(initialState.dashboard.tab, {
  [types.DASHBOARD_SET_DEFINITION]: (state, { data }) => {
    const newState = deepmerge({}, state)
    const { tabs } = data
    for (const tab of tabs || []) {
      if (!newState[tab.id]) {
        newState[tab.id] = {
          maximized: '',
          positionTop: null,
        }
      }
      newState[tab.id].name = tab.name || ''
      newState[tab.id].widgets = tab.widgets || []
    }
    return newState
  },
  [types.DASHBOARD_SET_POSITION_TOP]: (state, { tab, value }) => {
    if (state[tab]) {
      return {
        ...state,
        [tab]: {
          ...state[tab],
          positionTop: value,
        },
      }
    }
    return state
  },
  [types.DASHBOARD_WIDGET_MAXIMIZE]: (state, { tab, widget }) => ({
    ...state,
    [tab]: {
      ...state[tab],
      maximized: widget,
    },
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.dashboard.tab,
  }),
})

const widgetReducer = createReducer(initialState.dashboard.widget, {
  [types.DASHBOARD_WIDGET_SET]: (state, { data }) => {
    const newState = deepmerge({}, state)
    for (const key in data) {
      const widget = data[key]
      if (newState[key]) {
        newState[key].graph = widget.graph || newState[key].graph
        newState[key].total = widget.total || newState[key].total
      } else {
        newState[key] = {
          graph: widget.graph,
          total: widget.total,
        }
      }
    }
    return newState
  },
  [types.DASHBOARD_SET_DEFINITION]: (state, { data }) => {
    const newState = deepmerge({}, state)
    const { widgets } = data
    for (const widget of widgets || []) {
      if (!newState[widget.id]) {
        newState[widget.id] = {
          loading: false,
          invisible: [],
        }
      }
      const settings = {}
      for (const setting of widget.settings) {
        settings[setting.name] = setting.value
      }
      newState[widget.id].settings = settings
      newState[widget.id].invisible = widget.invisible
    }
    return newState
  },
  [types.DASHBOARD_WIDGET_LEGEND_VISIBLE]: (state, { widget, id }) => {
    return {
      ...state,
      [widget]: {
        ...state[widget],
        invisible: (state[widget].invisible || []).filter((x) => x !== id),
      },
    }
  },
  [types.DASHBOARD_WIDGET_LEGEND_INVISIBLE]: (state, { widget, id }) => {
    return {
      ...state,
      [widget]: {
        ...state[widget],
        invisible: [...(state[widget].invisible || []), id],
      },
    }
  },
  [types.DASHBOARD_WIDGET_SET_SETTING]: (state, { payload }) => {
    const { widget, setting, value } = payload
    const newState = deepmerge({}, state)
    if (
      newState[widget] &&
      setting
      //&& typeof newState[widget].settings[setting] !== 'undefined'
    ) {
      newState[widget].settings[setting] = value
    }
    return newState
  },
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.dashboard.widget,
  }),
})

export default combineReducers({
  info: infoReducer,
  tab: tabReducer,
  widget: widgetReducer,
})
