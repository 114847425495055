import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import deepmerge from 'deepmerge'

const activateReducer = createReducer(initialState.auth.activate, {
  [types.AUTH_ACTIVATE_INFO_SUCCEED]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.AUTH_ACTIVATE_SET_RESEND]: (state, { value }) => ({
    ...state,
    resend: value,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.activate,
})

const companyReducer = createReducer(initialState.auth.company, {
  [types.COMPANY_DELETE_MY_PHOTO_SUCCEED]: (state) => ({
    ...state,
    photoUrl: null,
  }),
  [types.AUTH_COMPANY_SET_SSO]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [types.COMPANY_SET_PIPELINE]: (state, { pipeline }) => ({
    ...state,
    pipeline,
  }),
  [types.COMPANY_GET_HIRING_SUCCEED]: (state, { data }) => ({
    ...state,
    hiring: data,
  }),
  [types.COMPANY_GET_PHASES_SUCCEED]: (state, { data }) => ({
    ...state,
    phases: data,
  }),
  [types.COMPANY_GET_WEBHOOKS_SUCCEED]: (state, { data }) => ({
    ...state,
    webhooks: data,
  }),
  [types.AUTH_SET_COMPANY_SEARCH]: (state, { value }) => ({
    ...state,
    search: value,
  }),
  [types.COMPANY_SET_MY_DATA]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [types.COMPANY_SET_SAVING]: (state, { saving }) => ({
    ...state,
    saving,
  }),
  [types.COMPANY_TEST_WEBHOOK]: (state) => ({
    ...state,
    webhookTesting: true,
  }),
  [types.COMPANY_TEST_WEBHOOK_RESULT]: (state) => ({
    ...state,
    webhookTesting: false,
  }),
  [types.COMPANY_UPLOAD_MY_PHOTO_SUCCEED]: (state, { url }) => ({
    ...state,
    photoUrl: url,
  }),
  [types.COMPANY_UPDATE_SETTING]: (state, { id, value }) => {
    const newState = deepmerge({}, state)
    newState.settings[id] = value
    return newState
  },
  [types.AUTH_SESSION]: (state, action) => ({
    ...state,
    ...action.data.company,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.company,
  }),
})

const infoReducer = createReducer(initialState.auth.info, {
  [types.AUTH_FORCE_REFRESH]: (state) => ({
    ...state,
    refresh: state.refresh + 1,
  }),
  [types.AUTH_INITIALIZE_SYSTEM_SUCCEED]: (state) => ({
    ...state,
    initialized: true,
  }),
  [types.AUTH_PREVIEW_MODE_SUCCEED]: (state, { value }) => ({
    ...state,
    previewMode: value,
  }),
  [types.AUTH_SET_AUTHENTICATED]: (state, { value }) => ({
    ...state,
    authenticated: value,
  }),
  [types.AUTH_SIGNIN_SUCCEED]: (state, action) => {
    return {
      ...state,
      ...action.data.info,
      authenticated: true,
    }
  },
  [types.AUTH_UPDATE_INFO]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => {
    return {
      ...initialState.auth.info,
      authenticated: false,
    }
  },
})

const nylasReducer = createReducer(initialState.auth.nylas, {
  [types.AUTH_GET_ACCOUNTS_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      accounts: data,
    }
  },
  [types.AUTH_REGISTER_NYLAS_BUSY]: (state, { value }) => {
    return {
      ...state,
      busy: value,
    }
  },
})

const partnerReducer = createReducer(initialState.auth.partner, {
  [types.AUTH_SET_PARTNER]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.partner,
})

const sessionsReducer = createReducer(initialState.auth.sessions, {
  [types.AUTH_SET_SESSIONS]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.sessions,
})

const sourcesReducer = createReducer(initialState.auth.sources, {
  [types.AUTH_SOURCES_SET_DATA]: (state, { entity, data }) => ({
    ...state,
    entity,
    list: data,
  }),
  [types.AUTH_SOURCES_SET_FILTER]: (state, { value }) => ({
    ...state,
    filter: value,
  }),
  [types.AUTH_SOURCES_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.sources,
})

const subscriptionReducer = createReducer(initialState.auth.subscription, {
  [types.AUTH_SET_SUBSCRIPTION]: (state, { data }) => data,
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.subscription,
})

const tagsReducer = createReducer(initialState.auth.tags, {
  [types.AUTH_TAGS_SET_DATA]: (state, { entity, data }) => ({
    ...state,
    entity,
    list: data,
  }),
  [types.AUTH_TAGS_SET_FILTER]: (state, { value }) => ({
    ...state,
    filter: value,
  }),
  [types.AUTH_TAGS_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.auth.tags,
})

const tenantReducer = createReducer(initialState.auth.tenant, {
  [types.AUTH_SESSION]: (state, action) => ({
    ...state,
    ...action.data.tenant,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.tenant,
  }),
})

const twoFactorReducer = createReducer(initialState.auth.twoFactor, {
  [types.AUTH_SET_QRCODE]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.twofactor,
  }),
})

const userReducer = createReducer(initialState.auth.user, {
  [types.AUTH_CANCEL_CHANGE_EMAIL_SUCCEED]: (state, { account }) => {
    if (account) {
      return {
        ...state,
        newEmail: undefined,
      }
    } else {
      return {
        ...state,
        newEmail2: undefined,
      }
    }
  },
  [types.AUTH_GET_ACCOUNT_STATUS_SUCCEED]: (state, { data }) => {
    return {
      ...state,
      nylas: state.nylas.map((x) => {
        let status = x.status
        let calendarId = x.calendarId
        if (x.accountId === data.accountId) {
          calendarId = data.calendarId
          status = data.status
        }
        return {
          ...x,
          calendarId,
          status,
        }
      }),
    }
  },

  [types.AUTH_SESSION]: (state, action) => ({
    ...state,
    ...action.data.user,
  }),
  [types.AUTH_SET_EMAIL]: (state, { email }) => ({
    ...state,
    email,
  }),
  [types.AUTH_SET_NEW_EMAIL]: (state, { email, account }) => {
    if (account) {
      return {
        ...state,
        newEmail: email,
      }
    } else {
      return {
        ...state,
        newEmail2: email,
      }
    }
  },
  [types.AUTH_SET_TWOFACTOR_ENABLED]: (state, action) => ({
    ...state,
    twoFactorEnabled: action.enabled,
  }),
  [types.USER_DELETE_MY_PHOTO_SUCCEED]: (state) => ({
    ...state,
    photoUrl: null,
  }),
  [types.USER_SAVE_DEFAULT_TEMPLATE]: (state, { payload }) => {
    const { template, context, label = '', toEntity = '' } = payload
    const templates = state.settings.defaultTemplates.filter(
      (x) =>
        !(x.context === context && x.label === label && x.toEntity === toEntity)
    )
    templates.push({ context, template, label, toEntity })
    return {
      ...state,
      settings: { ...state.settings, defaultTemplates: templates },
    }
  },
  [types.USER_SAVE_FILTER]: (state, { payload }) => {
    const { entity, id, inverse, order, sort, visible } = payload
    const item = { ...state.settings.filters[entity].find((x) => x.id === id) }
    item.entity = entity
    item.id = id
    if (typeof item.visible === 'undefined') {
      item.visible = true
    }
    if (typeof visible !== 'undefined') {
      item.visible = visible
    }
    if (typeof order !== 'undefined') {
      item.order = order
    }
    if (!item.sort) {
      item.sort = 'numberDesc'
    }
    if (sort) {
      item.sort = sort
    }
    if (typeof inverse !== 'undefined') {
      item.inverse = inverse
    }

    return {
      ...state,
      settings: {
        ...state.settings,
        filters: {
          ...state.settings.filters,
          [entity]: [
            ...state.settings.filters[entity].filter((x) => x.id !== id),
            item,
          ],
        },
      },
    }
  },
  [types.USER_SET_MY_DATA]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [types.USER_SET_SAVING]: (state, { saving }) => ({
    ...state,
    saving,
  }),
  [types.USER_UPLOAD_MY_PHOTO_SUCCEED]: (state, { url }) => ({
    ...state,
    photoUrl: url,
  }),
  [types.AUTH_SIGNIN_SUCCEED]: (state, action) => ({
    ...state,
    ...action.data.user,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({
    ...initialState.auth.user,
  }),
})

export default combineReducers({
  activate: activateReducer,
  company: companyReducer,
  info: infoReducer,
  nylas: nylasReducer,
  partner: partnerReducer,
  sessions: sessionsReducer,
  sources: sourcesReducer,
  subscription: subscriptionReducer,
  tags: tagsReducer,
  tenant: tenantReducer,
  twoFactor: twoFactorReducer,
  user: userReducer,
})
