import * as types from './types'
import { createAction } from 'Utils/redux'

export const activate = createAction(types.COMPANY_ACTIVATE, 'payload')
export const clearCache = createAction(types.COMPANY_CLEAR_CACHE)
export const connectRobin = createAction(types.COMPANY_CONNECT_ROBIN, 'payload')
export const create = createAction(types.COMPANY_CREATE, 'payload')
export const deactivate = createAction(types.COMPANY_DEACTIVATE, 'payload')
export const deleteMyPhoto = createAction(types.COMPANY_DELETE_MY_PHOTO)
export const deleteMyPhotoSucceed = createAction(
  types.COMPANY_DELETE_MY_PHOTO_SUCCEED
)
export const edit = createAction(types.COMPANY_EDIT, 'payload')
export const getHiring = createAction(types.COMPANY_GET_HIRING)
export const getHiringSucceed = createAction(
  types.COMPANY_GET_HIRING_SUCCEED,
  'data'
)
export const getMy = createAction(types.COMPANY_GET_MY)
export const getPhases = createAction(types.COMPANY_GET_PHASES, 'pipeline')
export const getPhasesSucceed = createAction(
  types.COMPANY_GET_PHASES_SUCCEED,
  'data'
)
export const getWebhooks = createAction(types.COMPANY_GET_WEBHOOKS)
export const getWebhooksSucceed = createAction(
  types.COMPANY_GET_WEBHOOKS_SUCCEED,
  'data'
)
export const putMy = createAction(types.COMPANY_PUT_MY, 'values')
export const removeWebhook = createAction(
  types.COMPANY_REMOVE_WEBHOOK,
  'publishOn'
)
export const renewToken = createAction(types.COMPANY_RENEW_TOKEN, 'token')
export const setMyData = createAction(types.COMPANY_SET_MY_DATA, 'data')
export const setPipeline = createAction(types.COMPANY_SET_PIPELINE, 'pipeline')
export const setSaving = createAction(types.COMPANY_SET_SAVING, 'saving')
export const testWebhook = createAction(types.COMPANY_TEST_WEBHOOK, 'payload')
export const testWebhookResult = createAction(
  types.COMPANY_TEST_WEBHOOK_RESULT,
  'data'
)
export const updateWebhook = createAction(
  types.COMPANY_UPDATE_WEBHOOK,
  'payload'
)
export const updatePhase = createAction(
  types.COMPANY_UPDATE_PHASE,
  'id',
  'values'
)
export const updateSetting = createAction(
  types.COMPANY_UPDATE_SETTING,
  'id',
  'value'
)
export const updateScope = createAction(
  types.COMPANY_UPDATE_SCOPE,
  'id',
  'values'
)
export const updateTenantName = createAction(
  types.COMPANY_UPDATE_TENANT_NAME,
  'name'
)
export const uploadMyPhoto = createAction(types.COMPANY_UPLOAD_MY_PHOTO, 'file')
export const uploadMyPhotoSucceed = createAction(
  types.COMPANY_UPLOAD_MY_PHOTO_SUCCEED,
  'url'
)
