import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import {
  deleteMyPhotoSucceed,
  getMy,
  getHiringSucceed,
  getPhases,
  getPhasesSucceed,
  getWebhooks,
  getWebhooksSucceed,
  setMyData,
  setSaving,
  testWebhookResult,
  updateSetting,
  uploadMyPhotoSucceed,
} from 'Actions/company'
import { hide } from 'redux-modal'
import { confirmSaga, globalError } from './shell'
import { t } from 'Root/app/IntlProvider'
import { loadCompanies } from 'Actions/cache'
import { messageLevel } from 'Utils/constants'
import { showMessage } from 'Actions/shell'

export function* activateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('company.message.activate.title'),
    content: t('company.message.activate.content', { company: name }),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/companies/${id}/activate`)
  if (ok) {
    yield put(loadCompanies())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* clearCacheSaga() {
  const { ok, error, data } = yield http.post('/companies/my/clear-cache')
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* connectRobinSaga(action) {
  const {
    payload: { disconnect, reject, resolve },
  } = action
  const { ok, error, data } = yield http.post(
    `/robin/${disconnect ? 'disconnect' : 'connect'}`
  )
  if (ok) {
    // ok
    yield put(getMy())
    yield call(resolve)
  } else if (error) {
    yield put(showMessage(data.error, messageLevel.error))
    yield call(reject)
  }
}

export function* createSaga(action) {
  const {
    payload: { values, reject, resolve },
  } = action
  const { created, error, data } = yield http.post(`/companies`, values)
  if (created) {
    yield put(loadCompanies())
    yield call(resolve)
  } else if (error) {
    if (data.error === 'Name already exists') {
      yield put(
        showMessage(t('company.message.error.name'), messageLevel.error, 10000)
      )
      yield call(reject)
    } else {
      yield call(globalError, data, reject)
    }
  }
}

export function* deactivateSaga(action) {
  const {
    payload: { id, name },
  } = action
  const yes = yield call(confirmSaga, {
    title: t('company.message.deactivate.title'),
    content: t('company.message.deactivate.content', { company: name }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/companies/${id}`)
  if (noContent) {
    yield put(loadCompanies())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteMyPhotoSaga() {
  const ok = yield call(confirmSaga, {
    title: t('company.message.deleteMyPhoto.title'),
    content: t('company.message.deleteMyPhoto.content'),
  })
  if (!ok) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/companies/my/photo`)
  if (noContent) {
    yield put(deleteMyPhotoSucceed())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* editSaga(action) {
  const {
    payload: { id, values, reject, resolve },
  } = action
  const state = yield select()
  const { ok, error, data } = yield http.put(`/companies/${id}`, values)
  if (ok) {
    yield put(loadCompanies())
    if (id === state.auth.company.id) {
      yield put(getMy())
    }
    yield call(resolve)
  } else if (error) {
    if (data.error === 'Name already exists') {
      yield put(
        showMessage(t('company.message.error.name'), messageLevel.error)
      )
      yield call(reject)
    } else {
      yield call(globalError, data, reject)
    }
  }
}

export function* getHiringSaga() {
  const { ok, error, data } = yield http.get('/companies/hiring')
  if (ok) {
    yield put(getHiringSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getMySaga() {
  const { ok, error, data } = yield http.get('/companies/my')
  if (ok) {
    yield put(setMyData(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getPhasesSaga(action) {
  let { pipeline } = action
  if (!pipeline) {
    const state = yield select()
    pipeline = state.auth.company.pipeline
  }
  const { ok, error, data } = yield http.get(`/companies/phases/${pipeline}`)
  if (ok) {
    yield put(getPhasesSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getWebhooksSaga() {
  const { ok, error, data } = yield http.get('/companies/webhooks')
  if (ok) {
    yield put(getWebhooksSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* putMySaga(action) {
  const { values } = action
  yield put(setSaving(true))
  yield put(setMyData(values))
  const { ok, error, data } = yield http.put('/companies/my', values)
  if (ok) {
    yield put(setMyData(data))
  } else if (error) {
    if (data.error === 'Name already exists') {
      yield put(
        showMessage(t('company.message.error.name'), messageLevel.error)
      )
    } else {
      yield call(globalError, data)
    }
  }
  yield put(setSaving(false))
}

export function* removeWebhookSaga(action) {
  const { publishOn } = action
  const ok = yield call(confirmSaga, {
    title: t('company.message.removeWebhook.title'),
    content: t('company.message.removeWebhook.content'),
  })
  if (!ok) {
    return
  }
  yield put(hide('webhook'))
  const { noContent, error, data } = yield http.delete(
    `/companies/webhooks/${publishOn}`
  )
  if (noContent) {
    yield put(getMy())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* renewTokenSaga(action) {
  const { token } = action
  const yes = yield call(confirmSaga, {
    title: t('template.message.company.renewToken.title', { token }),
    content: t('template.message.company.renewToken.content', {
      template: name,
    }),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.put(`/companies/connect/${token}`)
  if (ok) {
    yield put(setMyData(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* testWebhookSaga(action) {
  const { payload } = action
  const { ok, error, data } = yield http.post(
    '/companies/webhooks/test',
    payload
  )
  if (ok) {
    const { status } = data
    yield put(
      showMessage(
        t('company.message.testWebhook.result', {
          status,
          data: data.statusText,
        }),
        status === 200 ? messageLevel.succes : messageLevel.error,
        10000
      )
    )
    yield put(testWebhookResult({ status, data: data.statusText }))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updatePhaseSaga(action) {
  const { id, values } = action
  const { ok, error, data } = yield http.put(`/companies/phases/${id}`, values)
  if (ok) {
    yield put(getPhases())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateScopeSaga(action) {
  const { id, values } = action
  yield put(updateSetting(id, values))
  const { ok, error, data } = yield http.put(`/companies/scope/${id}`, {
    scopes: values,
  })
  if (ok) {
    // ok
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateTenantNameSaga(action) {
  const { name } = action
  const { ok, error, data } = yield http.put('/companies/tenant', { name })
  if (ok) {
    yield put(getMy())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateWebhookSaga(action) {
  const { payload } = action
  const { ok, error, data } = yield http.post('/companies/webhooks', payload)
  if (ok) {
    yield put(getWebhooks())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* uploadMyPhotoSaga(action) {
  const { file } = action
  const ext = ((file || {}).name || '').toLowerCase().split('.').pop()
  const types = ['jpg', 'jpeg', 'png', 'bmp', 'webp', 'heic', 'heif', 'svg']
  if (!types.includes(ext)) {
    // message
    yield put(
      showMessage(
        t('common.form.upload.error.file', { types: types.join(', ') }),
        messageLevel.error
      )
    )
    return
  }
  const { ok, error, data } = yield http.post(`/companies/my/photo`, file)
  if (ok) {
    yield put(uploadMyPhotoSucceed(data.url))
  } else if (error) {
    yield call(globalError, data)
  }
}
