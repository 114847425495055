import { combineReducers } from 'redux'
import * as types from 'Actions/types'
import initialState from './initialState'
import { createReducer } from 'Utils/redux'
import { getNewPositions } from 'Utils/lookup'
import deepmerge from 'deepmerge'
import { exists, t } from 'Root/app/IntlProvider'

const entitiesReducer = createReducer(initialState.cache.entities, {
  [types.CACHE_ADD_COMPANIES]: (state, action) => ({
    ...state,
    companies: action.data,
  }),
  [types.CACHE_ADD_USERS]: (state, action) => ({
    ...state,
    users: action.data,
  }),
  [types.CACHE_ADD_VIEWERS]: (state, action) => ({
    ...state,
    viewers: action.data,
  }),
  [types.CACHE_ENTITIES_SET_COMPANY_FILTER]: (state, { filter }) => ({
    ...state,
    companyFilter: filter,
  }),
  [types.CACHE_ENTITIES_SET_USER_FILTER]: (state, { filter }) => ({
    ...state,
    userFilter: filter,
  }),
  [types.CACHE_ENTITIES_SET_USER_FILTER_TYPE]: (state, { filter }) => ({
    ...state,
    userFilterType: filter,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.cache.entities,
})

const getTags = (state, { entityType, name }, add = true) => {
  let { [entityType]: tags = [], ...otherTags } = state
  const item = tags.find((x) => x.name === name)
  tags = (tags || []).filter((x) => x.name !== name)
  const number = !item ? 0 : item.number
  if (add) {
    tags = [...tags, { name, number: number + 1 }]
  } else if (number > 1) {
    tags = [...tags, { name, number: number - 1 }]
  }
  return {
    [entityType]: tags,
    ...otherTags,
  }
}

const filtersReducer = createReducer(initialState.cache.filters, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.cache.filters,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.cache.filters,
  [types.CACHE_ADD_FILTERS]: (state, action) => ({
    ...state,
    list: action.data,
    loaded: true,
  }),
})

const loadingReducer = createReducer(initialState.cache.loading, {
  [types.CACHE_LOADING_COMPANIES]: (state, { value }) => ({
    ...state,
    companies: value,
  }),
  [types.CACHE_LOADING_TEMPLATES]: (state, { value }) => ({
    ...state,
    templates: value,
  }),
  [types.CACHE_LOADING_USERS]: (state, { value }) => ({
    ...state,
    users: value,
  }),
})

const lookupsReducer = createReducer(initialState.cache.lookups, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => ({ ...initialState.cache.lookups }),
  [types.CACHE_ADD_LOOKUPS]: (state, { data }) => {
    const items = data
    for (const item in items) {
      const props = items[item]
      for (const prop in props) {
        const property = props[prop]
        if (!property.name) {
          property.name = t(`lookup.${item}.${prop}`)
        }
        const info = `lookup.${item}.info.${prop}`
        if (property.system && !property.info && exists(info)) {
          property.info = t(info)
        }
      }
    }
    return { ...state, ...items }
  },
  [types.LOOKUP_ADD_ITEM_SUCCEED]: (state, { list, value, name }) => {
    const newItem = {
      name,
      order: 0,
      mutation: { tenant: true },
    }
    return {
      ...state,
      [list]: {
        [value]: newItem,
        ...state[list],
      },
    }
  },
  [types.LOOKUP_DELETE_ITEM_SUCCEED]: (state, { list, value }) => {
    const { [value]: item, ...items } = state[list]
    const newItem = deepmerge({}, item)
    newItem.mutation = { ...newItem.mutation, deleted: true }

    if (!state.lookupList[list].noMove) {
      newItem.order = 999
    }
    return {
      ...state,
      [list]: {
        ...items,
        [value]: newItem,
      },
    }
  },
  [types.LOOKUP_MOVE_ITEM_INTERN]: (
    state,
    { list, oldPosition, newPosition }
  ) => {
    const { [list]: currentList, ...lists } = state
    const positions = getNewPositions(currentList, oldPosition, newPosition)
    const newList = deepmerge({}, currentList)
    let index = 0
    for (const item of positions) {
      index++
      newList[item].order = index
    }
    return { ...lists, [list]: newList }
  },
  [types.LOOKUP_RESTORE_ITEM_SUCCEED]: (state, { list, value }) => {
    const { [value]: item, ...items } = state[list]
    const newItem = deepmerge({}, item)
    newItem.mutation = { ...newItem.mutation, deleted: false }
    return {
      ...state,
      [list]: {
        ...items,
        [value]: newItem,
      },
    }
  },
  [types.LOOKUP_UPDATE_ITEM_SUCCEED]: (
    state,
    { child, childArray, code, color, list, value, name, info, required }
  ) => {
    const { [value]: item, ...items } = state[list]
    const newItem = deepmerge({}, item)
    newItem.child = child
    newItem.childArray = childArray
    newItem.name = name
    newItem.info = info
    if (typeof color !== 'undefined') {
      newItem.color = color
    }
    if (typeof code !== 'undefined') {
      newItem.code = code
    }
    if (typeof required !== 'undefined') {
      newItem.required = required
    }
    return {
      ...state,
      [list]: {
        [value]: newItem,
        ...items,
      },
    }
  },
})

const tagsReducer = createReducer(initialState.cache.tags, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.cache.tags,
  [types.AUTH_CHANGE_COMPANY_SUCCEED]: () => initialState.cache.tags,
  [types.CACHE_ADD_TAG]: (state, action) => getTags(state, action, true),
  [types.CACHE_ADD_TAGS](state, action) {
    return {
      ...state,
      [action.entityType]: action.data,
    }
  },
  [types.CACHE_CLEAR_TAGS](state, action) {
    return {
      ...state,
      [action.entityType]: [],
    }
  },
  [types.CACHE_REMOVE_TAG]: (state, action) => getTags(state, action, false),
})

const templatesReducer = createReducer(initialState.cache.templates, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => [...initialState.cache.templates],
  [types.CACHE_ADD_TEMPLATES]: (state, action) => action.data,
})

export default combineReducers({
  entities: entitiesReducer,
  filters: filtersReducer,
  loading: loadingReducer,
  lookups: lookupsReducer,
  tags: tagsReducer,
  templates: templatesReducer,
})
